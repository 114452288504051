<template>
  <sign-page
    title-text="入党积极分子"
    :request="request"
    :column-list="columnList"
    :form-parms-add="formParmsAdd"
    @dataFileUpdate="dataFileUpdate"
    :title-menus="[{key: 'add', label: '新增'}]"
    table-size="large"
    :tableActions="tableActions"
    :init-add-form-data="initAddFormData"
    :form-parms-update="formParmsUpdate"
    :tableActionsFixed="true"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  workerCertificateRequest as request
} from '../../api'

import {
  dateOperating
} from '@/fmlib'

import {
  loadData,
  loadSelectWorkerList
} from '../../auth_lib'

export default {
  created () {
    if (this.$authFunsProxy.add || this.$authFunsProxy.edit) {
      loadSelectWorkerList(this)
    }
  },
  computed: {
    tableActions () {
      let data = [{
        key: 'data_file',
        label: '附件'
      },
      {
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
      return data
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '姓名',
          key: 'name'
        },
        {
          type: 'input',
          label: '身份证号码',
          key: 'sfzh'
        },
        {
          type: 'datePicker',
          label: '申请入党时间',
          key: 'rdsqsj'
        },
        {
          type: 'datePicker',
          label: '积极分子备案时间',
          key: 'jjfzbasj'
        },
        {
          type: 'datePicker',
          label: '积极分子回执时间',
          key: 'jjfzhzsj'
        },
        {
          type: 'input',
          label: '培养联系人1',
          key: 'pylxr1'
        },
        {
          type: 'input',
          label: '联系人1联系方式',
          key: 'pylxr1lxfs'
        },
        {
          type: 'input',
          label: '培养联系人2',
          key: 'pylxr2'
        },
        {
          type: 'input',
          label: '联系人2联系方式',
          key: 'pylxr2lxfs'
        },
        {
          type: 'datePicker',
          label: '填报时间',
          key: 'tbsj'
        },
        {
          type: 'input',
          label: '填报人',
          key: 'tbr'
        },
        {
          type: 'datePicker',
          label: '审核时间',
          key: 'shsj'
        },
        {
          type: 'input',
          label: '审核人',
          key: 'shr'
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {
            title: item.label,
            field: item.key
          }
          if (item.type === 'datePicker') {
            newItem.render = (h, rowData) => {
              return h('div', rowData && rowData[item.key] ? rowData[item.key].slice(0, 10) : '-')
            }
          }
          data.push(newItem)
        })
        return data
      }
    }
  },
  methods: {
    async dataFileUpdate (parm, pageVm) {
      await request.update(pageVm.chooseData.id, {
        imgUploadTime: dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd hh:mm:ss'}),
        fileIds: parm ? parm.join(',') : ''
      })
      pageVm.loadData()
    },
    initAddFormData () {
      return {type: 'rdjjfz'}
    },
    async update (id, data) {
      let otherInfo = {}
      let d = {}
      let otherInfoKeys = this.formParmsAdd.map(v => v.key)
      Object.keys(data).forEach(key => {
        if (otherInfoKeys.includes(key)) {
          otherInfo[key] = data[key]
        } else {
          d[key] =data[key]
        }
      })
      d.updateLog = JSON.stringify(otherInfo)
      return await request.update(id, d)
    },
    async add (data) {
      let otherInfo = {}
      let d = {}
      let otherInfoKeys = this.formParmsAdd.map(v => v.key)
      Object.keys(data).forEach(key => {
        if (otherInfoKeys.includes(key)) {
          otherInfo[key] = data[key]
        } else {
          d[key] =data[key]
        }
      })
      d.updateLog = JSON.stringify(otherInfo)
      return await request.add(d)
    },
    async loadData () {
      let parm = {
        type: 'rdjjfz'
      }
      let data = await loadData(request, this, parm)
      data = data.map(v => {
        let otherInfo = v.updateLog ? JSON.parse(v.updateLog) : {}
        return Object.assign(v, otherInfo)
      })
      return data
    }
  },
  data () {
    return {
      choose: null,
      loading: false,
      request: Object.assign({}, request, {
        get: this.loadData,
        update: this.update,
        add: this.add
      })
    }
  }
}
</script>
